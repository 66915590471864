import React from "react";
import _ from "lodash";
import {
  sessionExpired,
  messageRefreshInterval,
  serverUrl,
  conversationData,
} from "../config/GeneralConfig";
import {
  setMessageStatus,
  getConversation,
  updateMessage,
  sendMessage,
} from "../modules/MessageManager";
import {
  showSpinner,
  closeSpinner,
  modal,
  alert,
  adminModerateMessage,
  adminEditMessage,
} from "../modules/Dialogs";
import { toScreen, goBack } from "../modules/Routing";
import PropTypes from "prop-types";
import withNavigate from "./withNavigate";
import { io } from "socket.io-client";
class Message extends React.Component {
  constructor(props) {
    super(props);

    this.getConversation = this.getConversation.bind(this);
    this.getConversation_done = this.getConversation_done.bind(this);
    this.setConversationReadStatus = this.setConversationReadStatus.bind(this);
    this.setConversationReadStatus_done =
      this.setConversationReadStatus_done.bind(this);
    this.moderateMessage = this.moderateMessage.bind(this);
    this.updateMessage = this.updateMessage.bind(this);
    this.updateMessage_done = this.updateMessage_done.bind(this);
    this.renderMessages = this.renderMessages.bind(this);
    this.sendMessage = this.sendMessage.bind(this);
    this.discardMessage = this.discardMessage.bind(this);
    this.sendMessage_done = this.sendMessage_done.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.extraAttributes = window.history.state.usr;
    if (this.extraAttributes) {
      this.joinRoomPayload = {
        receiverId: this.extraAttributes?.receiverId,
        receiverShortId: this.extraAttributes?.receiverShortId,
        receiverType: this.extraAttributes?.receiverType,
      };
    } else {
      this.joinRoomPayload = null;
    }
    console.log(this.extraAttributes, "this.extraAttributes");
    this.state = {
      updateOnUnmount: {
        messages: false,
      },
      _id: "",
      short_id: "",
      conversation: {},
      messages: {},
      newMessage: "",
    };
    this.state.socket = null;
  }

  setConversationReadStatus() {
    if (this.state._id && this.state._id != "") {
    }
    var theConversation = {
      accountInfo: {
        username: this.props.userDetails.accountInfo.username,
        email: this.props.userDetails.accountInfo.email,
      },
      conversation: this.state.short_id,
      message: "",
      readStatus: "read",
    };

    setMessageStatus(
      theConversation,
      this.setConversationReadStatus_done,
      this.props.userDetails.jwttoken
    );
  }

  setConversationReadStatus_done(resStatus, responseString, resJson) {
    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
    } else if (resStatus == 200) {
      this.setState({
        ...this.state,
        updateOnUnmount: {
          ...this.state.updateOnUnmount,
          messages: true,
        },
        messages: resJson.messages,
      });
    }
  }

  getConversation(event) {
    if (this.state.short_id == "") {
      showSpinner();
    }
    if (event) {
      event.preventDefault();
    }

    const theUser = {
      accountInfo: {
        username: this.props.userDetails.accountInfo.username,
        email: this.props.userDetails.accountInfo.email,
      },
    };
    theUser.participants = JSON.stringify([
      this.extraAttributes.userShortId1 === "admin"
        ? "Admin"
        : this.extraAttributes.userShortId1,
      this.extraAttributes.userShortId2,
    ]);

    getConversation(
      theUser,
      this.getConversation_done,
      this.props.userDetails.jwttoken
    );
  }

  getConversation_done(resStatus, responseString, resJson) {
    closeSpinner();
    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      modal(responseString, "Something went wrong!");
    } else if (resStatus == 200) {
      const inputChangeCallback = () => {
        this.setConversationReadStatus();
      };

      if (
        !_.isEqual(resJson.conversation, this.state.conversation) ||
        !_.isEqual(resJson.participants, this.state.participants)
      ) {
        this.setState(
          {
            ...this.state,
            short_id: resJson.short_id,
            conversation: Object.values(resJson.conversation),
            participants: resJson.participants,
          },
          inputChangeCallback
        );
      }
      //** COMMENTED for no need to call conversation api every 5 seconds */
      // if (!this.interval) {
      // 	this.interval = setInterval(() => {
      // 		this.getConversation();
      // 	}, messageRefreshInterval);
      // }
    }
  }

  updateMessage(event, key) {
    // CLEAR INTERVAL HERE

    if (event) {
      event.preventDefault();
    }

    if (this.state.conversation[key]) {
      var theMessage = {
        conversation_short_id: this.state.short_id,
        message: {
          _id: this.state.conversation[key]._id,
          message: this.state.conversation[key].message,
          moderation: {
            flags:
              this.state.conversation[key].moderation &&
              this.state.conversation[key].moderation.flags
                ? this.state.conversation[key].moderation.flags
                : "",
            moderatorMessage:
              this.state.conversation[key].moderation &&
              this.state.conversation[key].moderation.moderatorMessage
                ? this.state.conversation[key].moderation.moderatorMessage
                : "",
            status:
              this.state.conversation[key].moderation &&
              this.state.conversation[key].moderation.status
                ? this.state.conversation[key].moderation.status
                : "",
          },
        },
      };

      updateMessage(
        theMessage,
        this.updateMessage_done,
        this.props.userDetails.jwttoken
      );
    }
  }

  updateMessage_done(resStatus, responseString, resJson) {
    // REASTART INTERVAL HERE

    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      modal(responseString);
    } else if (resStatus == 200) {
      //modal('Message successfully updated');

      this.getConversation(false);
    }
  }

  moderateMessage(event, key) {
    if (event) {
      event.preventDefault();
    }

    if (this.state.conversation[key].userType != "admin") {
      var editMessage = { ...this.state.conversation[key] };

      const approveMessageCallback = () => {
        this.setState(
          {
            ...this.state,
            conversation: {
              ...this.state.conversation,
              [key]: {
                ...this.state.conversation[key],
                moderation: {
                  ...this.state.conversation[key].moderation,
                  status: "approved",
                },
              },
            },
          },
          () => {
            this.updateMessage(false, key);
          }
        );
      };

      const rejectMessageCallback = () => {
        this.setState(
          {
            ...this.state,
            conversation: {
              ...this.state.conversation,
              [key]: {
                ...this.state.conversation[key],
                moderation: {
                  ...this.state.conversation[key].moderation,
                  status: "rejected",
                },
              },
            },
          },
          moderatorMessageCallback
        );
      };

      const saveMessageCallback = (theMode, theMessage, theModerateMessage) => {
        this.setState(
          {
            ...this.state,
            conversation: {
              ...this.state.conversation,
              [key]: {
                ...this.state.conversation[key],
                message:
                  theMode == "edit"
                    ? theMessage
                    : this.state.conversation[key].message,
                moderation: {
                  ...this.state.conversation[key].moderation,
                  status: theMode == "reject" ? "rejected" : "edited",
                  moderatorMessage: theModerateMessage || "",
                },
              },
            },
          },
          () => {
            this.updateMessage(false, key);
          }
        );
      };

      const editMessageCallback = () => {
        adminEditMessage(
          this.state.conversation[key],
          saveMessageCallback,
          "edit"
        );
      };

      const moderatorMessageCallback = () => {
        adminEditMessage(
          this.state.conversation[key],
          saveMessageCallback,
          "reject"
        );
      };

      adminModerateMessage(
        this.state.conversation[key],
        approveMessageCallback,
        rejectMessageCallback,
        editMessageCallback
      );
    }
  }

  renderMessages(key) {
    //u-cursor-pointer
    const message = this.state.conversation[key];
    const isClientsChat = message.userType === "user";
    const isAdminChat = message.userType === "admin";
    const printFlags = (theFlags) => {
      var flagString = " (";

      _.forEach(theFlags, function (value) {
        if (!flagString || flagString == " (") {
          flagString += '"' + value + '"';
        } else {
          flagString += ', "' + value + '"';
        }
      });

      flagString += " )";

      return flagString;
    };
    // return (
    //   <>
    //     <div
    //       key={key}
    //       className={`msg ${isClientsChat ? "right-msg" : "left-msg"}`}
    //     >
    //       <div
    //         className="msg-bubble"
    //         onClick={(e) => {
    //           this.moderateMessage(e, key);
    //         }}
    //       >
    //         <div className="msg-info">
    //           <div className="msg-info-name">
    //             <span
    //               className={!isAdminChat && "u-cursor-pointer"}
    //               onClick={(e) => {
    //                 if (message.userType !== "admin") {
    //                   toScreen(
    //                     e,
    //                     this.props.navigate,
    //                     message.userType === "user"
    //                       ? `/client/${this.extraAttributes.userShortId2}`
    //                       : message.userType === "mav"
    //                       ? `/mav/${this.extraAttributes.userShortId1}`
    //                       : null,
    //                     false,
    //                     this.extraAttributes
    //                   );
    //                 }
    //               }}
    //             >
    //               {isAdminChat
    //                 ? "Admin"
    //                 : message.user?.accountInfo?.username ??
    //                   this.extraAttributes.username2}
    //             </span>

    //             <span className="disputeLable">
    //               {message.disputeFlag &&
    //               message.disputedJob_short_id &&
    //               message.disputedJob_short_id != "" ? (
    //                 <button
    //                   onClick={(e) =>
    //                     toScreen(
    //                       e,
    //                       this.props.navigate,
    //                       `/job/${message.disputedJob_short_id}`
    //                     )
    //                   }
    //                 >
    //                   <i className="u-font-size-12 u-color-red">
    //                     Disputing job: {message.disputedJob_short_id}
    //                   </i>
    //                 </button>
    //               ) : null}
    //             </span>

    //             <span className="quoteInfoLable">
    //               {message.associatedQuoteShortID &&
    //               message.associatedQuoteShortID != "" ? (
    //                 <i className="u-font-size-12">
    //                   &nbsp;Message about quote :{" "}
    //                   {message.associatedQuoteShortID}
    //                 </i>
    //               ) : null}
    //             </span>
    //             {message?.moderation?.status != "" &&
    //               message?.moderation?.status != "approved" && (
    //                 <>
    //                   {message.moderation.status == "flagged" && (
    //                     <span className="u-before-icon-exclamation u-color-red">
    //                       &nbsp;Flagged message{" "}
    //                       {message.moderation.flags &&
    //                       _.size(message.moderation.flags) > 0
    //                         ? printFlags(message.moderation.flags)
    //                         : null}
    //                     </span>
    //                   )}

    //                   {message.moderation.status == "rejected" && (
    //                     <span className="u-before-icon-exclamation">
    //                       &nbsp;Rejected message
    //                     </span>
    //                   )}

    //                   {message.moderation.status == "edited" && (
    //                     <span className="u-before-">&nbsp;Edited message</span>
    //                   )}
    //                   {message?.moderation?.moderatorMessage != "" && (
    //                     <span className="c-message-moderation-moderatorcomment">
    //                       "{message?.moderation?.moderatorMessage}"
    //                     </span>
    //                   )}
    //                 </>
    //               )}
    //           </div>
    //           <div className="msg-info-time">
    //             {message?.time} on {message?.date}
    //           </div>
    //         </div>
    //         <div className="msg-text">{message?.message}</div>
    //       </div>
    //     </div>
    //   </>
    // );
    if (this.extraAttributes.userShortId1 !== "admin") {
      return (
        <div key={key}>
          <div
            onClick={(e) => {
              this.moderateMessage(e, key);
            }}
            className={
              message.userType === "admin" ||
              (this.extraAttributes.userShortId2 && message.userType === "mav")
                ? message.moderation &&
                  message.moderation.status &&
                  message.moderation.status != "" &&
                  message.moderation.status == "rejected"
                  ? "c-message-bubble c-message-bubble--rejected u-margin-bottom-0 u-cursor-pointer"
                  : "c-message-bubble u-margin-bottom-0 u-cursor-pointer"
                : message.moderation &&
                  message.moderation.status &&
                  message.moderation.status != "" &&
                  message.moderation.status == "rejected"
                ? "c-message-bubble c-message-bubble--user c-message-bubble--rejected u-margin-bottom-0 u-cursor-pointer"
                : "c-message-bubble c-message-bubble--user u-margin-bottom-0 u-cursor-pointer"
            }
          >
            <div className="c-message-bubble__copy left-chat">
              <b
                className="u-font-size-12 text-capitalize"
                onClick={(e) => {
                  if (message.userType !== "admin") {
                    toScreen(
                      e,
                      this.props.navigate,
                      message.userType === "user"
                        ? `/client/${this.extraAttributes.userShortId2}`
                        : message.userType === "mav"
                        ? `/mav/${this.extraAttributes.userShortId1}`
                        : null,
                      false,
                      this.extraAttributes
                    );
                  }
                }}
              >
                {message.userType === "admin"
                  ? "Admin"
                  : message?.user?.accountInfo?.username ??
                    this.extraAttributes.username2}
              </b>
              {message.disputeFlag &&
              message.disputedJob_short_id &&
              message.disputedJob_short_id != "" ? (
                <i className="u-font-size-12 u-color-red">
                  &nbsp;Disputing job: {message.disputedJob_short_id}
                </i>
              ) : null}
              {message.associatedQuoteShortID &&
              message.associatedQuoteShortID != "" ? (
                <i className="u-font-size-12">
                  &nbsp;Message about quote :{" "}
                  <span className="u-uppercase">
                    {message.associatedQuoteShortID}
                  </span>
                </i>
              ) : null}
              <br />
              <span
                className={
                  message.moderation &&
                  message.moderation.status &&
                  message.moderation.status != "" &&
                  message.moderation.status == "flagged"
                    ? "u-color-red"
                    : null
                }
              >
                {message.message}
              </span>
            </div>
            <div>
              <div className="c-message-bubble__time u-font-size-12">
                {message.time} on {message.date}
              </div>
            </div>
          </div>
          <div
            className={
              message.userType === "admin" ||
              (this.extraAttributes.userShortId2 && message.userType === "mav")
                ? "c-message-moderation"
                : "c-message-moderation c-message-moderation--user"
            }
          >
            {message?.moderation?.status != "" &&
              message.moderation.status != "approved" && (
                <div className="c-message-moderation__copy">
                  {message.moderation.status == "flagged" && (
                    <span className="u-before-icon-exclamation u-color-red">
                      &nbsp;Flagged message{" "}
                      {message.moderation.flags &&
                      _.size(message.moderation.flags) > 0
                        ? printFlags(message.moderation.flags)
                        : null}
                      <br />
                    </span>
                  )}
                  {message.moderation.status == "rejected" && (
                    <span className="u-before-icon-exclamation">
                      &nbsp;Rejected message
                      <br />
                    </span>
                  )}
                  {message.moderation.status == "edited" && (
                    <span className="u-before-">
                      &nbsp;Edited message
                      <br />
                    </span>
                  )}
                  {message.moderation.moderatorMessage &&
                    message.moderation.moderatorMessage != "" && (
                      <span className="c-message-moderation-moderatorcomment">
                        "{message.moderation.moderatorMessage}"
                      </span>
                    )}
                </div>
              )}
          </div>
        </div>
      );
    } else {
      return (
        <div key={key}>
          <div
            className={`c-message-bubble ${
              (message?.userType === "mav" || message?.userType === "user") &&
              "c-message-bubble--user"
            } ${
              message?.moderation?.status == "rejected" &&
              message?.userType === "admin"
                ? "c-message-bubble--rejected"
                : "c-message-bubble--user--rejected"
            }`}
            // className={
            //   message.userType === "admin" ||
            //   (this.extraAttributes.userShortId2 && message.userType === "mav")
            //     ? message.moderation &&
            //       message.moderation.status &&
            //       message.moderation.status != "" &&
            //       message.moderation.status == "rejected"
            //       ? "c-message-bubble c-message-bubble--rejected"
            //       : "c-message-bubble"
            //     : message.moderation &&
            //       message.moderation.status &&
            //       message.moderation.status != "" &&
            //       message.moderation.status == "rejected"
            //     ? "c-message-bubble c-message-bubble--user c-message-bubble--user--rejected"
            //     : "c-message-bubble c-message-bubble--user"
            // }
          >
            <div className="c-message-bubble__copy">
              <b
                className={`u-font-size-12 text-capitalize ${
                  message.userType !== "admin" && "u-cursor-pointer"
                }`}
                onClick={(e) => {
                  if (message.userType !== "admin") {
                    toScreen(
                      e,
                      this.props.navigate,
                      `/${
                        this.extraAttributes.receiverType === "user"
                          ? "client"
                          : this.extraAttributes.receiverType
                      }/${this.extraAttributes.userShortId2}`,
                      // message.userType === "user"
                      //   ? `/client/${this.extraAttributes.userShortId2}`
                      //   : message.userType === "mav"
                      //   ? `/mav/${this.extraAttributes.userShortId1}`
                      //   : null,
                      false,
                      this.extraAttributes
                    );
                  }
                }}
              >
                {message.userType === "admin"
                  ? "Admin"
                  : message?.user?.accountInfo?.username ??
                    this.extraAttributes.username2}
              </b>{" "}
              {message.disputeFlag &&
              message.disputedJob_short_id &&
              message.disputedJob_short_id != "" ? (
                <button
                  onClick={(e) =>
                    toScreen(
                      e,
                      this.props.navigate,
                      `/job/${message.disputedJob_short_id}`
                    )
                  }
                >
                  <i className="u-font-size-12 u-color-red">
                    Disputing job:{" "}
                    <span className="u-uppercase">
                      {message.disputedJob_short_id}
                    </span>
                  </i>
                </button>
              ) : null}
              <br />
              <span
                className={
                  message.moderation &&
                  message.moderation.status &&
                  message.moderation.status != "" &&
                  message.moderation.status == "flagged"
                    ? "u-color-red"
                    : null
                }
              >
                {message.message}
              </span>
            </div>
            <div>
              <div className="c-message-bubble__time u-font-size-12">
                {message.time} on {message.date}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  sendMessage(event) {
    if (this.state?.newMessage) {
      showSpinner();

      //** NEW FUNCTIONALITY SEND MESSAGE BYSOCKET  */

      // if (event) {
      //   event.preventDefault();
      // }
      // const sendMessagePayloads = {
      //   ...this.joinRoomPayload,
      //   conversationShortId: this.state.short_id,
      //   messageType: "conversation",
      //   message: this.state?.newMessage,
      // };
      // if (this.socket?.connected) {
      //   this.socket.emit("send-message", sendMessagePayloads);
      //   this.socket.on(
      //     `send-message-error-${this.props.userDetails.short_id}`,
      //     (sendMessageErrorResp) => {
      //       if (sendMessageErrorResp) {
      //         modal(`ERROR: ${sendMessageErrorResp}`, "Something went wrong!"),
      //           closeSpinner();
      //       }
      //     }
      //   );
      //   let updatedConversations = this.state.conversation
      //   updatedConversations.push({message: this.state?.newMessage,
      //     date: new Date().toISOString().split('T')[0],
      //     time: new Date().toTimeString().split(' ')[0].slice(0, 5),
      //     userType: "admin"})
      //   this.setState({
      //     ...this.state,
      //     conversation: updatedConversations,
      //   });
      //   this.discardMessage()
      //   // this.setState({...this.state,conversation: {
      //   //   message: this.state?.newMessage,
      //   //   date: new Date().toISOString().split('T')[0],
      //   //   time: new Date().toTimeString().split(' ')[0].slice(0, 5),
      //   //   userType: "admin"
      //   // }})
      //   closeSpinner();
      // }
      // closeSpinner();

      //** NEW Send Message by Socket functionality END */

      //** OLD FUNCTIONALITY START*/
      const theUser = {
        accountInfo: {
          username: this.props.userDetails.accountInfo.username,
          email: this.props.userDetails.accountInfo.email,
        },
      };

      theUser.message = this.state.newMessage;
      theUser.conversation = this.state.short_id;

      sendMessage(
        theUser,
        this.sendMessage_done,
        this.props.userDetails.jwttoken
      );
      //** OLD FUNCTIONALITY END*/
    } else {
      modal("Please enter the message");
    }
  }

  discardMessage(event) {
    if (event) {
      event.preventDefault();
    }

    this.setState(
      {
        newMessage: "",
      }
      // () => {
      // 	goBack(false, this.props.navigate);
      // }
    );
  }

  sendMessage_done(resStatus, responseString, resJson) {
    closeSpinner();
    if (resStatus == 401) {
      modal(sessionExpired);
      toScreen(false, this.props.navigate, "/logout");
    } else if (resStatus == "400") {
      alert(responseString);
    } else if (resStatus == 200) {
      this.setState({
        ...this.state,
        conversation: Object.values(resJson.conversation),
        newMessage: "",
      });
    }
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name.split(".").length == 1) {
      this.setState({
        [name]: value,
      });
    } else if (name.split(".").length == 2) {
      this.setState({
        [name.split(".")[0]]: {
          ...this.state[name.split(".")[0]],
          [name.split(".")[1]]: value,
        },
      });
    }
  }

  componentWillMount() {
    // this.socket = io(`${serverUrl}`, {
    //   transports: ["websocket"],
    //   auth: {
    //     token: `Bearer ${this.props?.userDetails?.jwttoken}`,
    //   },
    //   reconnectionAttempts: 5,
    //   timeout: 20000,
    // });
  }
  componentDidMount() {
    this.getConversation();

    //** FETCH CONVERSATION BY SOCKET */

    // if (this.extraAttributes.userShortId1 === "admin") {
    //   showSpinner();
    //   if (this.joinRoomPayload) {
    //     this.socket.on("connect", async () => {
    //       this.socket.emit("join-room", this.joinRoomPayload);
    //       this.socket.on(
    //         `join-room-error-${this.props.userDetails.short_id}`,
    //         (errorJoinRoomResp) => {
    //           if (errorJoinRoomResp) {
    //             modal(`ERROR: ${errorJoinRoomResp}`, "Something went wrong!");
    //             this.setState({
    //               ...this.state,
    //               short_id: "",
    //               conversation: [],
    //               participants: [],
    //             });
    //             closeSpinner();
    //           }
    //         }
    //       );
    //       this.socket.on("conversation", (conversationResp) => {
    //         if (conversationResp) {
    //           this.setState({
    //             ...this.state,
    //             short_id: conversationResp?.short_id ?? "",
    //             conversation: conversationResp?.messages ?? [],
    //             participants: conversationResp?.participants ?? [],
    //           });
    //           closeSpinner();
    //         }
    //       });
    //     });
    //     this.socket.on("connection-error", (err) => {
    //       modal("Connection timeout, reload the page");
    //       closeSpinner();
    //     });
    //   } else {
    //     closeSpinner();
    //   }
    // } else {
    //   this.getConversation();
    // }
  }

  componentWillUnmount() {
    if (this.socket?.connected) {
      this.socket.emit("leave-room", { conversationId: this.state?.short_id });
      this.socket.disconnect();
    }

    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.state.updateOnUnmount.messages) {
      const userDetails = {
        ...this.props.userDetails,
        messages: this.state.messages,
      };

      this.props.updateState("userDetails", userDetails);
    }
  }

  componentDidUpdate() {
    if (this.socket?.connected) {
      this.socket.on("new-message", (recentMessage) => {
        if (recentMessage) {
          let userReceivedMsg = [...this.state.conversation];
          userReceivedMsg.push(recentMessage);
          this.setState({ conversation: userReceivedMsg });
          this.discardMessage();
        }
      });
    }
  }

  render() {
    return (
      <div className="l-base-layout__content">
        <h1>Admin / Message</h1>
        <div className="c-hr"></div>
        <div className="conversation-info-header">
          <div className="c-subheader-info-panel">
            <div className="c-subheader-info-panel__primary u-uppercase">
              ID: {this.state.short_id}
            </div>
          </div>
          <button
            type="button"
            className="c-btn c-btn--3 c-btn--small"
            onClick={() =>
              toScreen(
                false,
                this.props.navigate,
                this.extraAttributes.previousPath,
                false,
                {
                  selectedFilter: this.extraAttributes.selectedFilter,
                  selectedPage: this.extraAttributes.selectedPage,
                }
              )
            }
          >
            Back
          </button>
        </div>
        <div className="c-hr"></div>

        {this.extraAttributes.userShortId1 === "admin" && (
          <div className="l-row">
            <div className="l-col-66">
              <div className="c-input-wide">
                <form action="">
                  <textarea
                    rows="4"
                    className="c-input-wide__textarea"
                    value={this.state.newMessage}
                    onChange={this.handleInputChange}
                    name="newMessage"
                    placeholder="Write your message..."
                  ></textarea>
                </form>
              </div>
            </div>
            <div className="l-col-33">
              <div>
                <button
                  className="c-btn c-btn--small c-btn--full-width u-margin-bottom-xs"
                  onClick={(e) => this.sendMessage(e)}
                >
                  Send
                </button>
                <button
                  className="c-btn c-btn--small c-btn--full-width c-btn--3"
                  onClick={(e) => this.discardMessage(e)}
                >
                  Discard
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="conversation-body msger">
          <div
            class={`conversation-section ${
              this.extraAttributes.userShortId1 !== "admin" &&
              "user-conversation-section"
            }`}
          >
            {this.state.conversation.length > 0
              ? Object.keys(this.state.conversation)
                  .reverse()
                  .map(this.renderMessages)
              : this.state.conversation.length !== undefined && (
                  <span className="error-text">No conversations found</span>
                )}
          </div>
        </div>
      </div>
    );
  }
}

Message.contextTypes = {
  router: PropTypes.object,
};

Message.propTypes = {
  userDetails: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default withNavigate(Message);
