import React from "react";
import Dropdown from "./customSelect";
import PropTypes from "prop-types";
import infoIcon from "../../img/app/icons/info.svg";
import {
  getAllCities,
  getCityCounts,
  getClientsDriversCategoryCount,
  sendPushNotification,
} from "../modules/sendPushNotification";
import { closeSpinner, confirm, modal, showSpinner } from "../modules/Dialogs";
import { toScreen } from "../modules/Routing";
import { sessionExpired } from "../config/GeneralConfig";
class PushNotifications extends React.Component {
  constructor(props) {
    super(props);
    // Build all props here

    this.state = {
      // States initial values
      selectedUserType: "clients",
      selectedCategory: "",
      selectedCity: "allCities",
      notificationTitle: "",
      message: "",
      selectedDeepLinkOption: { value: "home", label: "Homescreen" },
      selectedCategoryCount: "",
      errors: {},
      fetchAllCities: [],
      selectedCityCount: "",
    };
  }

  componentWillMount() {
    //
  }

  componentDidMount() {
    this.handleDefaultSelectedCategory("allClients");
    //Get All Cities API Handler
    showSpinner();
    getAllCities((resStatus, responseString, resJson) => {
      closeSpinner();
      if (resStatus == 401) {
        modal(sessionExpired);
        toScreen(false, this.props.navigate, "/logout");
      } else if (resStatus == "400") {
        modal(responseString);
      } else if (resStatus == 200) {
        this.setState({
          fetchAllCities: resJson?.data,
        });
      }
    }, this.props.userDetails.jwttoken);
  }

  componentWillUnmount() {
    //
  }
  handleSelectedUserTypeChange = (event) => {
    const { value } = event.target;

    this.setState(
      (prevState) => ({
        selectedUserType: value,
        selectedCategory: "",
        selectedCategoryCount: "",
        selectedCity: "allCities",
        selectedDeepLinkOption: { value: "home", label: "Homescreen" },
      }),
      () => {
        // Call handleDefaultSelectedCategory after state is updated
        this.handleDefaultSelectedCategory(
          value === "clients"
            ? "allClients"
            : value === "drivers"
            ? "allDrivers"
            : ""
        );
      }
    );
  };

  handleDefaultSelectedCategory = (defaultCategory) => {
    this.handleSelectedCategoryChange({
      target: { value: defaultCategory },
    });
  };

  handleSelectedCategoryChange = (event) => {
    this.setState({ selectedCategory: event.target.value });

    let selectedCategory = event.target.value;
    showSpinner();
    const params = {
      type: this.state.selectedUserType,
      category: event.target.value,
    };
    getClientsDriversCategoryCount(
      params,
      (resStatus, responseString, resJson) => {
        closeSpinner();
        if (resStatus == 401) {
          modal(sessionExpired);
          toScreen(false, this.props.navigate, "/logout");
        } else if (resStatus == "400") {
          modal(responseString);
        } else if (resStatus == 200) {
          this.setState({
            selectedCategoryCount: { [selectedCategory]: `(${resJson?.data})` },
          });
        }
      },
      this.props.userDetails.jwttoken
    );
  };

  handleGetCityCounts = () => {
    showSpinner();
    const params = {
      type: this.state.selectedUserType,
      category: this.state.selectedCategory,
      city: this.state.selectedCity,
    };
    getCityCounts(
      params,
      (resStatus, responseString, resJson) => {
        closeSpinner();
        if (resStatus == 401) {
          modal(sessionExpired);
          toScreen(false, this.props.navigate, "/logout");
        } else if (resStatus == "400") {
          modal(responseString);
        } else if (resStatus == 200) {
          this.setState({
            selectedCityCount: resJson?.data,
          });
        }
      },
      this.props.userDetails.jwttoken
    );
  };

  handleSelectedCityChange = (event) => {
    this.setState({ selectedCity: event.target.value }, () => {
      // This callback runs after `selectedCity` state is updated
      this.handleGetCityCounts();
    });
  };

  handleTitleInputChange = (event) => {
    this.setState({ notificationTitle: event.target.value });
  };

  handleMessageChange = (event) => {
    this.setState({ message: event.target.value });
  };

  handleDeepLinkOptionChange = (selectedOption) => {
    this.setState({ selectedDeepLinkOption: selectedOption });
  };

  handleSubmitPushNotificationButton = () => {
    console.log(this.state.selectedDeepLinkOption);
    if (!this.validateFields()) return; // Stop if validation fails
    confirm(
      `Are you sure you want to send this notification to ${
        Object.keys(this.state.selectedCategoryCount)[0]
          ?.charAt(0)
          ?.toUpperCase() +
        Object.keys(this.state.selectedCategoryCount)[0]
          ?.slice(1)
          ?.replace(/([A-Z])/g, " $1")
      } ${
        this.state.selectedUserType === "drivers" &&
        this.state.selectedCityCount !== ""
          ? `(${this.state.selectedCityCount})`
          : this.state.selectedCategoryCount[
              Object.keys(this.state.selectedCategoryCount)[0]
            ]
      }?`,
      "",
      "Cancel",
      "Confirm",
      this.handlerSendPushNotification
    );
  };
  handlerSendPushNotification = () => {
    showSpinner();
    const payloads = {
      type: this.state.selectedUserType,
      category: this.state.selectedCategory,
      title: this.state.notificationTitle,
      message: this.state.message,
      deepLink: `/${this.state.selectedDeepLinkOption?.value}`,
      ...(this.state.selectedUserType !== "clients" && {
        city: this.state.selectedCity,
      }),
    };
    sendPushNotification(
      payloads,
      (resStatus, responseString, resJson) => {
        closeSpinner();
        if (resStatus == 401) {
          modal(sessionExpired);
          toScreen(false, this.props.navigate, "/logout");
        } else if (resStatus == "400") {
          modal(responseString);
        } else if (resStatus == 200) {
          modal(responseString, "Good Job", () => {
            // State update and callback to handleDefaultSelectedCategory are now properly ordered
            this.setState(
              {
                selectedUserType: "clients",
                selectedCategory: "",
                selectedCategoryCount: "",
                selectedCity: "allCities",
                selectedDeepLinkOption: { value: "home", label: "Homescreen" },
                notificationTitle: "",
                message: "",
                errors: {},
              },
              () => {
                // This callback is called after the state is updated
                this.handleDefaultSelectedCategory("allClients");
              }
            );
          });
        }
      },
      this.props.userDetails.jwttoken
    );
  };

  // Validation method
  validateFields = () => {
    const errors = {};
    const {
      selectedCategory,
      selectedCity,
      notificationTitle,
      message,
      selectedDeepLinkOption,
      selectedUserType,
    } = this.state;

    if (!selectedCategory) errors.selectedCategory = "Category is required.";
    if (selectedUserType === "drivers" && !selectedCity)
      errors.selectedCity = "City is required.";
    if (!notificationTitle) errors.notificationTitle = "Title is required.";
    else if (notificationTitle.length > 65)
      errors.notificationTitle = "Title must not exceed 65 characters.";
    if (!message) errors.message = "Message is required.";
    else if (message.length > 178)
      errors.message = "Message must not exceed 178 characters.";

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handleBlur = (event) => {
    const { name, value } = event.target;
    this.validateFields(name, value);
  };

  renderError = (fieldName) => {
    return this.state.errors[fieldName] ? (
      <span className="error-text">{this.state.errors[fieldName]}</span>
    ) : null;
  };

  render() {
    const { errors, fetchAllCities, selectedCityCount } = this.state;
    return (
      <div className="l-base-layout__content">
        <h1>Admin / Send Push Notifications</h1>
        <div className="c-hr"></div>

        <div className="l-col-33">
          <div className="user-filter-section mb-30">
            <span className="notifications-label">Select user type</span>
            <div className="c-checkbox-radio-block">
              <input
                type="radio"
                id="clients"
                name="user-filter-section"
                value="clients"
                onChange={this.handleSelectedUserTypeChange}
                checked={this.state.selectedUserType === "clients"}
              />
              <label htmlFor="clients">Clients</label>
            </div>
            <div className="c-checkbox-radio-block">
              <input
                type="radio"
                id="drivers"
                name="user-filter-section"
                value="drivers"
                onChange={this.handleSelectedUserTypeChange}
                checked={this.state.selectedUserType === "drivers"}
              />
              <label htmlFor="drivers">Drivers</label>
            </div>
          </div>
          <div className="category-filter-section mb-30">
            <span className="notifications-label">Select a category</span>
            {this.state.selectedUserType === "clients" && (
              <div className="select-dropdown">
                <select
                  className="c-input-wide__input"
                  value={this.state.selectedCategory}
                  onChange={this.handleSelectedCategoryChange}
                  onBlur={(e) => this.handleBlur(e)}
                  name="category-filter-section"
                >
                  <option value="">Please select</option>
                  <option value="allClients">{`All Clients ${
                    this.state.selectedCategoryCount.allClients ?? ""
                  }`}</option>
                  <option value="activeClients">{`Active ${
                    this.state.selectedCategoryCount.activeClients ?? ""
                  }`}</option>
                  <option value="inactiveClients">{`Inactive ${
                    this.state.selectedCategoryCount.inactiveClients ?? ""
                  }`}</option>
                  <option value="firstTimers">{`First timers ${
                    this.state.selectedCategoryCount.firstTimers ?? ""
                  }`}</option>
                  <option value="completedJobs">{`Completed jobs ${
                    this.state.selectedCategoryCount.completedJobs ?? ""
                  }`}</option>
                  <option value="repeatClients">{`Repeat clients ${
                    this.state.selectedCategoryCount.repeatClients ?? ""
                  }`}</option>
                </select>
                {this.renderError("selectedCategory")}
              </div>
            )}
            {this.state.selectedUserType === "drivers" && (
              <div className="select-dropdown">
                <select
                  className="c-input-wide__input"
                  value={this.state.selectedCategory}
                  onChange={this.handleSelectedCategoryChange}
                  onBlur={(e) => this.handleBlur(e)}
                  name="category-filter-section"
                >
                  <option value="">Please select</option>
                  <option value="allDrivers">{`All Drivers ${
                    this.state.selectedCategoryCount.allDrivers ?? ""
                  }`}</option>
                  <option value="activeDrivers">{`Active ${
                    this.state.selectedCategoryCount.activeDrivers ?? ""
                  }`}</option>
                  <option value="idleDrivers">{`Idle ${
                    this.state.selectedCategoryCount.idleDrivers ?? ""
                  }`}</option>
                  <option value="inactiveDrivers">{`Inactive ${
                    this.state.selectedCategoryCount.inactiveDrivers ?? ""
                  }`}</option>
                  <option value="noQuoteDrivers">{`Haven't quoted ${
                    this.state.selectedCategoryCount.noQuoteDrivers ?? ""
                  }`}</option>
                  <option value="zeroJobDrivers">{`Zero jobs ${
                    this.state.selectedCategoryCount.zeroJobDrivers ?? ""
                  }`}</option>
                  <option value="experiencedDrivers">{`Experienced ${
                    this.state.selectedCategoryCount.experiencedDrivers ?? ""
                  }`}</option>
                </select>
                {this.renderError("selectedCategory")}
              </div>
            )}
          </div>
          {this.state.selectedUserType === "drivers" && (
            <div className="city-filter-section mb-30">
              <span className="notifications-label">Select a city</span>
              <div className="select-dropdown">
                <select
                  className="c-input-wide__input"
                  value={this.state.selectedCity}
                  onChange={this.handleSelectedCityChange}
                  onBlur={(e) => this.handleBlur(e)}
                  name="city-filter-section"
                >
                  <option value="allCities" selected>
                    {`All Cities (${fetchAllCities.length})`}
                  </option>
                  {fetchAllCities?.map((item, index) => (
                    <option key={index} value={item.city}>
                      {item.city}{" "}
                      {this.state.selectedCity === item.city
                        ? `(${selectedCityCount})`
                        : ""}
                    </option>
                  ))}
                </select>
                {this.renderError("selectedCity")}
              </div>
            </div>
          )}
        </div>

        <div className="l-col-33">
          <div className="title-input-section mb-30">
            <label className="notifications-label">
              Title <span>65 chars max — only available on Android</span>
            </label>
            <input
              type="text"
              value={this.state.notificationTitle}
              onChange={this.handleTitleInputChange}
              onBlur={(e) => this.handleBlur(e)}
              name="notificationTitle"
              placeholder="Type your title here"
              className="c-input-wide__input"
            />
            {this.renderError("notificationTitle")}
          </div>
          <div className="message-input-section mb-30">
            <label className="notifications-label">
              Message <span>178 chars max</span>
            </label>
            <textarea
              type="text"
              value={this.state.message}
              onChange={this.handleMessageChange}
              onBlur={(e) => this.handleBlur(e)}
              name="message"
              placeholder="Type your message here"
              className="c-input-wide__input"
            />
            {this.renderError("message")}
          </div>
          <div className="deeplink-filter-section mb-30">
            <label className="notifications-label">Deeplink to screen</label>
            <Dropdown
              selectedUserType={this.state.selectedUserType}
              selectedDeepLinkOption={this.state.selectedDeepLinkOption}
              handleDeepLinkOptionChange={this.handleDeepLinkOptionChange}
            />
            {errors.selectedDeepLinkOption && (
              <div className="error-text">{errors.selectedDeepLinkOption}</div>
            )}
          </div>
          <div className="send-notification-button">
            <button
              className="c-btn c-btn--small c-btn--full-width  c-btn--yellow c-btn--black-text u-margin-bottom-0"
              onClick={() => this.handleSubmitPushNotificationButton()}
            >
              <span>
                {this.state.selectedCategoryCount
                  ? `Send Push Notification to ${
                      Object.keys(this.state.selectedCategoryCount)[0]
                        ?.charAt(0)
                        ?.toUpperCase() +
                      Object.keys(this.state.selectedCategoryCount)[0]
                        ?.slice(1)
                        ?.replace(/([A-Z])/g, " $1")
                    } ${
                      this.state.selectedUserType === "drivers" &&
                      selectedCityCount !== ""
                        ? `(${selectedCityCount})`
                        : this.state.selectedCategoryCount[
                            Object.keys(this.state.selectedCategoryCount)[0]
                          ]
                    }`
                  : "Send Push Notification"}
              </span>
            </button>
          </div>
        </div>

        <div className="l-col-33">
          <div className="tag-notification">
            <img src={infoIcon} alt="Info Icon" />
            <p>
              Tags you can use in your notification:
              <span>{`{{first_name}} {{last_name}}`}</span>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

PushNotifications.contextTypes = {
  router: PropTypes.object,
};

PushNotifications.propTypes = {
  userDetails: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired,
};

export default PushNotifications;
